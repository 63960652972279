<template>
        <section class="advanced-form-elements new-updated-design">
            <vue-snotify></vue-snotify>
            <system_app_settings></system_app_settings>
             <br>
            <div class="row">
                <div class="col-md-12 grid-margin">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{{ $t('field-guide') }}</h4>
                        </div>
                        <form @submit.stop.prevent="onSubmitEditor">
                            <div class="card-body new-card-body">
                                <h3 class="card-title">{{ $t('field-guide-info') }}</h3>
                                <ckeditor :editor="editor" v-model="editorForm.editor_content" :config="editorConfig"
                                          :height="120" :rows="6" @input="onEditorInput" @ready="onReady">
                                    <div class="document-editor">
                                        <div class="document-editor__toolbar"></div>
                                        <div class="document-editor__editable-container">
                                            <div class="document-editor__editable">
                                                <p>The initial editor data.</p>
                                            </div>
                                        </div>
                                    </div>
                                </ckeditor>
                                <div class="row">
                                    <div class="col-md-6"></div>
                                    <div class="col-md-6">
                                        <b-button type="submit" variant="success" class="mt-3 float-right"
                                                  :disabled="isDisabled1">{{ $t('submit-btn') }}
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </section>

</template>

<script lang="js">
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import Vue from "vue";
    import CKEditor from '@ckeditor/ckeditor5-vue2';
    import CkEditorBuild from 'ckeditor5-custom-build/build/ckeditor';
    import UploadAdapter from '@/UploadAdapter';
    import {validationMixin} from "vuelidate";
    // import VueSlideBar from 'vue-slide-bar'
    import system_app_settings from './system_app_settings.vue';
    

    const options = {
        toast: {
            timeout: 2000,
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(CKEditor);
    Vue.use(Snotify, options)
    export default {
        name: 'textEditor',
        mixins: [validationMixin],
        components: {
            // VueSlideBar
            system_app_settings
        },
        data() {
            return {
                form: {
                    header_color: "",
                    font_color: "",
                    // training_complete: true,
                    // enable_acoustic: true,
                    require_approval: true,
                    require_lat_lang: true,
                    require_photo: true,
                    latitude: "",
                    longitude: "",
                    default_map_zoom: "",
                    default_map: "mapbox://styles/mapbox/light-v10",
                    stock_image: [],
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                },
                sliderCustomzie: {
                    lineHeight: 10,
                    processStyle: {
                        backgroundColor: '#008ffb'
                    },
                    tooltipStyles: {
                        backgroundColor: '#008ffb',
                        borderColor: '#008ffb'
                    }
                },
                editorForm: {
                    editor_content: "",
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                },
                editor: CkEditorBuild,
                editorConfig: {
                    extraPlugins: [this.uploader],
                    toolbar: {
                        items: [
                            'heading',
                            '|',
                            'htmlEmbed',
                            // 'pageBreak',
                            'fontSize',
                            'fontFamily',
                            'fontColor',
                            'fontBackgroundColor',
                            '|',
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                            '|',
                            'alignment',
                            '|',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'link',
                            'blockQuote',
                            'imageUpload',
                            'insertTable',
                            'mediaEmbed',
                            '|',
                            'undo',
                            'redo',
                        ]
                    }, language: 'cs',
                    image: {
                        toolbar: [
                            'imageTextAlternative',

                            'imageStyle:center',
                        ]
                    },
                    table: {
                        contentToolbar: [
                            'tableColumn',
                            'tableRow',
                            'mergeTableCells'
                        ]
                    },
                },
                selectedFile: null,
                image_error: false,
                url: null,
                client_url: null,
                isDisabled: false,
            }
        },

        methods: {
            onSubmitEditor() {
                this.isDisabled1 = true;
                API.updateEditorContent(
                    this.editorForm,
                    data => {
                        this.isDisabled1 = false;
                        this.$snotify.success(data.message, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    },
                    err => {
                        this.isDisabled1 = false;
                        this.$snotify.error(err.message, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                )
            },

            onFileSelected(event) {
                var img = new Image();
                var target_file = event.target.files[0];
                img.src = URL.createObjectURL(target_file);


                img.onload = () => {
                    console.log(img.naturalWidth, img.naturalHeight)
                    if ((img.naturalWidth === 50 && img.naturalHeight === 50) || (img.naturalWidth === 27 && img.naturalHeight === 27)) {
                        this.image_error = false;
                        this.selectedFile = event.target.files[0]
                        this.url = URL.createObjectURL(this.selectedFile)
                    } else {
                        this.image_error = true;
                    }
                }
            },
            getAppSettings() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getAppSettings(
                    data,
                    data => {
                        this.editorForm.editor_content = data.data.editor_content;
                        this.form.header_color = data.data.header_color;
                        this.form.font_color = data.data.font_color;
                        this.form.latitude = data.data.latitude;
                        this.form.longitude = data.data.longitude;
                        this.form.default_map_zoom = data.data.default_map_zoom;
                        this.form.default_map = (data.data.default_map) ? data.data.default_map : 'mapbox://styles/mapbox/light-v10';
                        this.form.require_photo = (data.data.require_photo === 1) ? true : false;
                        this.form.require_lat_lang = (data.data.require_lat_lang === 1) ? true : false;
                        this.form.require_approval = (data.data.require_approval === 1) ? true : false;
                        // this.form.training_complete = (data.data.training_complete === 1) ? true : false;
                        // this.form.enable_acoustic = (data.data.enable_acoustic === 1) ? true : false;
                        this.url = data.data.image;
                    },
                    err => {
                    }
                )
            },
            getClientSettings() {
                this.clientSettings = JSON.parse(localStorage.getItem('client_settings'));
                if (this.clientSettings) {
                    this.client_url = this.clientSettings.url;
                }
            },
            handleAttachmentChanges(event) {
                // 1. get file object
                let file = event.attachment.file;

                let data = {
                    upload: file
                }

                API.saveEditorImage(
                    data,
                    data => {
                        let attributes = {
                            url: data.url,
                            href: data.url
                        };
                        event.attachment.setAttributes(attributes);
                    },
                    err => {
                    }
                )
                // 2. upload file to remote server with FormData
                // ...

                // 3. if upload success, set back the attachment's URL attribute
                // @param object data from remote server response data after upload.
                // let attributes = {
                //     url: remoteHost + data.path,
                //     href: remoteHost + data.path
                // };
                // event.attachment.setAttributes(attributes);
            },
            removeImage() {
                this.selectedFile = [];
                this.url = '';
            },
            onEditorInput(event) {
            },
            uploader(editor) {
                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                    return new UploadAdapter(loader);
                };
            },
            onReady(editor) {
                editor.ui.getEditableElement().parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                );
            },
            // saveMapStyle(value) {
            //     console.log(value)
            //     this.default_map = value;
            // }
        }
        ,
        beforeMount() {

            this.getAppSettings();
            this.getClientSettings();
        }
    }
</script>
<style scoped>

    .document-editor {
        border: 1px solid var(--ck-color-base-border);
        border-radius: var(--ck-border-radius);

        /* Set vertical boundaries for the document editor. */
        max-height: 700px;

        /* This element is a flex container for easier rendering. */
        display: flex;
        flex-flow: column nowrap;
    }

    .document-editor__toolbar {
        /* Make sure the toolbar container is always above the editable. */
        z-index: 1;

        /* Create the illusion of the toolbar floating over the editable. */
        box-shadow: 0 0 5px hsla(0, 0%, 0%, .2);

        /* Use the CKEditor CSS variables to keep the UI consistent. */
        border-bottom: 1px solid var(--ck-color-toolbar-border);
    }

    /* Adjust the look of the toolbar inside the container. */
    .document-editor__toolbar .ck-toolbar {
        border: 0;
        border-radius: 0;
    }

    /* Make the editable container look like the inside of a native word processor application. */
    .document-editor__editable-container {
        padding: calc(2 * var(--ck-spacing-large));
        background: var(--ck-color-base-foreground);

        /* Make it possible to scroll the "page" of the edited content. */
        overflow-y: scroll;
    }

    .document-editor__editable-container .ck-editor__editable {
        /* Set the dimensions of the "page". */
        /*width: 15.8cm;*/
        /*min-height: 21cm;*/

        /* Keep the "page" off the boundaries of the container. */
        padding: 1cm 2cm 2cm;

        border: 1px hsl(0, 0%, 82.7%) solid;
        border-radius: var(--ck-border-radius);
        background: white;

        /* The "page" should cast a slight shadow (3D illusion). */
        box-shadow: 0 0 5px hsla(0, 0%, 0%, .1);

        /* Center the "page". */
        margin: 0 auto;
    }

    /* Set the default font for the "page" of the content. */
    .document-editor .ck-content,
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    /* Adjust the headings dropdown to host some larger heading styles. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        line-height: calc(1.7 * var(--ck-line-height-base) * var(--ck-font-size-base));
        min-width: 6em;
    }

    /* Scale down all heading previews because they are way too big to be presented in the UI.
    Preserve the relative scale, though. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
        transform: scale(0.8);
        transform-origin: left;
    }

    /* Set the styles for "Heading 1". */
    .document-editor .ck-content h2,
    .document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
        font-size: 2.18em;
        font-weight: normal;
    }

    .document-editor .ck-content h2 {
        line-height: 1.37em;
        padding-top: .342em;
        margin-bottom: .142em;
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3,
    .document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
        font-size: 1.75em;
        font-weight: normal;
        color: hsl(203, 100%, 50%);
    }

    .document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
        color: var(--ck-color-list-button-on-text);
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3 {
        line-height: 1.86em;
        padding-top: .171em;
        margin-bottom: .357em;
    }

    /* Set the styles for "Heading 3". */
    .document-editor .ck-content h4,
    .document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
        font-size: 1.31em;
        font-weight: bold;
    }

    .document-editor .ck-content h4 {
        line-height: 1.24em;
        padding-top: .286em;
        margin-bottom: .952em;
    }

    /* Set the styles for "Paragraph". */
    .document-editor .ck-content p {
        font-size: 1em;
        line-height: 1.63em;
        padding-top: .5em;
        margin-bottom: 1.13em;
    }

    /* Make the block quoted text serif with some additional spacing. */
    .document-editor .ck-content blockquote {
        font-family: Georgia, serif;
        margin-left: calc(2 * var(--ck-spacing-large));
        margin-right: calc(2 * var(--ck-spacing-large));
    }

    .ck.ck-editor__editable_inline {
        border: 1px solid #e3e3e3;
        overflow: auto;
        padding: 0 var(--ck-spacing-standard);
    }

</style>
